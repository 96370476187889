import Link from "next/link";
import DashboardStyle from "../styles/Dashboard.module.scss";
import { useEffect } from "react";
import Image from "next/image";

export default function FourOhFour(props) {
  const goBack = () => {
    history.back();
  };

  return (
    <div className={DashboardStyle.notFound}>
      <img src={"/images/gtiLogo.svg?cb=2110"} style={{ width: "46px" }} />
      <h1>Sorry, the page was not found</h1>
      <div className={DashboardStyle.goBack} onClick={(ev) => goBack()}>
        Go back
      </div>
    </div>
  );
}
